import { saveComposerSetting } from './app';


export const updateCustomDomain = () => {
  return (dispatch, getState) => {
    dispatch(saveComposerSetting('custom_domain'));
  }
};

export const UPDATE_CUSTOM_DOMAIN = 'UPDATE_CUSTOM_DOMAIN';
export const changeCustomDomainState = (value) => {
  return {
    type: UPDATE_CUSTOM_DOMAIN,
    value
  }
}

import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Add from '@material-ui/icons/Add';
import ColorPicker from './input_types/ColorPicker';
import TextInput from './input_types/TextInput';
import Select from './input_types/Select';
import FontFamilySelector from './input_types/FontFamilySelector';
import Checkbox from './input_types/Checkbox';
import ImagePicker from './input_types/ImagePicker';
import HtmlEditor from './input_types/HtmlEditor';
import JsEditor from './input_types/JsEditor';
import SlidesManager from './input_types/SlidesManager';
import LinksManager from './input_types/LinksManager';
import ColorManager from './input_types/ColorManager';
import ParticipantInformationManager from './input_types/ParticipantInformationManager';
import AttendeeTypesSelector from './input_types/AttendeeTypesSelector';
import BorderManager from './input_types/BorderManager';
import IframeSettings from './input_types/IframeSettings';
import TranslatableButton from './TranslatableButton';
import getPropertyInfo, { inputTypes } from '../constants/PropertyTypes';

const styles = {
  inputWrapper: {
    margin: '1rem 0',
    display: 'flex',
    fontSize: '0.875rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > label': {
      marginRight: '1em',
      display: 'inline-block'
    },
    '& > label::first-letter': {
      textTransform: 'uppercase'
    }
  }
};

class PropertyInput extends Component {
  onChange = (value) => {
    const { id, onChange } = this.props;
    onChange(id, value);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.value !== this.props.value;
  }

  render() {
    const { classes, propertyName, value, toggleContentList } = this.props;
    const propertyInfo = getPropertyInfo(propertyName);
    let input;

    switch (propertyInfo.inputType) {
      case inputTypes.ALLOWED_CONTENTS:
        input = (!!value && !!value.length) ? (
          <TranslatableButton
            onClick={toggleContentList}
            tPath={'buttons.add_content'}
            leftIcon={<Add />}
            buttonProps={{fullWidth: true}}
          />
        ) : null;
        break;
      case inputTypes.COLOR:
        input = (
          <ColorPicker
            value={value}
            onChange={this.onChange}
          />
        );
        break;
      case inputTypes.IMAGE:
        input = (
          <ImagePicker
            value={value}
            onChange={this.onChange}
          />
        );
        break;
      case inputTypes.SELECT:
        input = (
          <Select
            value={value}
            onChange={this.onChange}
            options={propertyInfo.options}
          />
        );
        break;
      case inputTypes.FONT_FAMILY_SELECTOR:
        input = (
          <FontFamilySelector
            value={value}
            onChange={this.onChange}
            options={propertyInfo.options}
          />
        );
        break;
      case inputTypes.CHECKBOX:
        input = (
          <Checkbox
            value={value}
            onChange={this.onChange}
          />
        );
        break;
      case inputTypes.HTML:
        input = (
          <HtmlEditor
            value={value}
            onChange={this.onChange}
          />
        );
        break;
      case inputTypes.JS:
        input = (
          <JsEditor
            value={value}
            onChange={this.onChange}
          />
        );
        break;
      case inputTypes.CAROUSEL_SLIDES:
        input = (
          <SlidesManager
            value={value}
            onChange={this.onChange}
          />
        );
        break;
      case inputTypes.LINK_SELECTOR:
        input = (
          <LinksManager
            value={value}
            onChange={this.onChange}
          />
        );
        break;
      case inputTypes.COLOURS_SELECTOR:
        input = (
          <ColorManager
            value={value}
            onChange={this.onChange}
          />
        );
        break;
      case inputTypes.PARTICIPANT_INFORMATION:
        input = (
          <ParticipantInformationManager
            value={value}
            onChange={this.onChange}
          />
        );
        break;
      case inputTypes.BORDERS_SELECTOR:
        input = (
          <BorderManager
            value={value}
            onChange={this.onChange}
          />
        );
        break;
      case inputTypes.IFRAME_SETTINGS:
        input = (
          <IframeSettings
            value={value}
            onChange={this.onChange}
          />
        );
        break;
      case inputTypes.ATTENDEE_TYPES_SELECTOR:
        input = (
          <AttendeeTypesSelector
            value={value}
            onChange={this.onChange}
          />
        );
        break;
      case inputTypes.TEXT:
      case inputTypes.URL:
      default:
        input = (
          <TextInput
            value={value}
            onChange={this.onChange}
          />
        );
    }

    return !!input ? (
      <div className={classes.inputWrapper}>
        {propertyInfo.label && <label>{propertyInfo.label}</label>}
        {input}
      </div>
    ) : null;
  }

}

export default injectSheet(styles)(PropertyInput);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import TranslatableIconButton from '../components/TranslatableIconButton';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Done from '@material-ui/icons/Done';
import Domain from '@material-ui/icons/Domain';
import { changeCustomDomainState, updateCustomDomain } from "../actions/customDomain";
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';

const styles = theme => ({
  formControl:{
    margin: '0 1rem',
  },
  settingHeader:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    '& .setting-name':{
      paddingLeft: '1rem',
      fontSize: '24px'
    },
  },
  settingInputs:{
    marginBottom: '3rem',
    display: 'flex',
    justifyContent: 'center',
    '& .top-panel':{
      padding:'1rem',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  inputRoot: {
    maxWidth: '100%',
    width: '30em',
  },
});

class CustomDomainSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.customDomain,
      hasChanged: false
    };
  }

  componentDidUpdate(prevProps){
    if(!prevProps.customDomain && !!this.props.customDomain)
      this.setState({ inputValue: this.props.customDomain });
  }

  onChangeInput = (event) => {
    this.setState({ inputValue: event.target.value, hasChanged: true });
  }

  handleUpdate = () => {
    const { inputValue } = this.state;
    this.props.changeCustomDomainState(inputValue);
    this.props.updateCustomDomain();
    this.setState({ hasChanged: false });
  }

  render(){
    const { classes, t } = this.props;
    return(
      <React.Fragment>
        <div className={classes.settingHeader}>
          <p className='width-icon'><Domain/> </p>
          <p className='setting-name'>{t('labels.custom_domain')}</p>
        </div>
        <div className={classes.settingInputs}>
          <div className='top-panel'>
            <FormControl className={classes.formControl} error aria-describedby="component-error-text">
              <TextField
                value={this.state.inputValue}
                onChange={this.onChangeInput}
                classes={{root: classes.inputRoot}}
              />
            </FormControl>
            <TranslatableIconButton
              tPath={"buttons.update"}
              onClick={this.handleUpdate}
              Icon={Done}
              buttonProps={{ disabled: !this.state.hasChanged }}
              divButtonProps={{ style: { display:'inline-block', height: '48px', margin: '0 1rem' } }}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ otherData }) => ({
  customDomain: otherData.customDomain
})

const mapDispatchToProps = {
  updateCustomDomain,
  changeCustomDomainState,
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(translate('Forms')(CustomDomainSettings)));

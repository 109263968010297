import * as blockTypes from 'composer-blocks/lib/constants/BlockTypes';

export default {
  en: {
    placeholder: {
      ageGateDescription: 'Ex: You must be 18 or older to enter.',
      ageGateErrorMsg: 'Ex: You are not old enough to enter this site.',
    },
    buttons: {
      close: 'Close',
      back: 'Back',
      back_to_composer: 'Back to Composer',
      restore: 'Restore',
      add_content: 'Add Content',
      select_image: 'Select Image',
      manage_slides: 'Manage Slides',
      add_slide: 'Add Slide',
      manage_links: 'Manage Links',
      manage_iframe_src: "Manage Iframe link",
      manage_information: 'Manage Information',
      manage_colours: 'Manage colors',
      manage_attendee_types: 'Manage attendee types',
      add_font: 'Add font',
      add_link: 'Add Link',
      add_color: 'Add Color',
      apply_changes: 'Apply Changes',
      discard_changes: 'Discard Changes',
      url_image: 'Image from URL',
      upload_image: 'Upload image',
      edit_code: 'Edit Code',
      delete_page: 'Delete Page',
      duplicate_page: 'Duplicate Page',
      copy_block: 'Copy block',
      paste_block: 'Paste block',
      duplicate_block: 'Duplicate block',
      copy_content: 'Copy content',
      paste_content: 'Paste content',
      change_friendly_url: 'Change friendly URL',
      move: 'Move',
      delete: 'Delete',
      copy: 'Copy',
      paste: 'Paste',
      duplicate: 'Duplicate',
      select_font: 'Select Font',
      change_font: 'Change font',
      update_global_font: 'Update site fonts',
      edit: 'Edit',
      update:"Update",
    },
    errors:{
      not_image_format: 'Invalid image format',
      not_font_format: "Invalid font format",
      exceeded_limit: 'File size limit exceeded',
      empty_name: 'The name field is required',
      empty_password: 'Password cannot be empty',
    },
    info:{
      valid_font_formats: "Only ttf and woff formats are allowed.",
      first_font_is_global: 'The first font you choose will be used as default for this site',
      default_font_is: 'Default font: {{font}}',
    },
    labels: {
      attendee_fields: 'Attendee Fields',
      b2b_fields: 'Networking Fields',
      b2b_how_to: 'Choose the information to display for one participant type by dragging the available fields.',
      networking_profile: 'Networking profile',
      attendee_type: 'Attendee type',
      types_to_show:'Networking Profiles to be shown on the website',
      hide_navbar: 'Hide Navbar',
      hide_from_navbar: 'Hide from the Navbar',
      hide_footer: 'Hide Footer',
      hide_from_footer: 'Hide from the Footer',
      text: 'Text',
      open_in_new_tab: 'Open link in new tab',
      description: 'Description (optional)',
      name: 'Name (required)',
      scoped_css_note: 'Note: please use appropriately scoped rules in your custom CSS; using global rules might negatively affect the styles of rest of your site.',
      js_note: 'Note: please be mindful of what you insert here; any syntax or runtime errors might break your website.',
      placeholder_b2b_drop: 'Drop or write the fields you want to use inside ${}. For example ${Myfield}',
      fonts: 'Fonts',
      site_fonts: 'Fonts used in this site',
      fonts_in_account: 'Fonts in your account',
      font_preview: 'Preview',
      max_width: 'Site Max Width',
      custom_domain: 'Custom domain',
      allow_indexing: 'Allow search engine indexing',
      indexing:'Search engine indexing',
      site_password: 'Site password',
      enable_site_password: 'Enable site password',
      backdrop_color: 'Backdrop color',
      popup_color: 'Pop up color',
      button_color: 'Button color',
      error_msg: 'Error message',
      minimum_age: 'Minimum age',
      security: 'Security',
      enable_age_gate: 'Enable age restriction to site',
      password: 'Password',
      login_main_color: 'Login main color',
      iframe_note: "To guarantee a correct resizing of the iframe, the target website must have the iframe-resizer library script.",
    },
    warnings:{
      create_conference_and_participant: 'Please create a conference and at least one participant',
    },
    block_types: {
      [blockTypes.REGISTRATION]: 'Registration',
      [blockTypes.REGISTRATION_TYPES]: 'Registration types',
      [blockTypes.B2B_MATCHMAKING]: 'B2B matchmaking',
      [blockTypes.COOKIE_CONSENT]: 'Cookie consent',
      [blockTypes.NETWORKING_LOGIN]: 'Networking login',
      [blockTypes.ATTENDEES]: 'Attendees',
      [blockTypes.SPEAKERS]: 'Speakers',
      [blockTypes.SPONSORS]: 'Sponsors',
      [blockTypes.NAVBAR]: 'Navbar',
      [blockTypes.FOOTER]: 'Footer',
      [blockTypes.CUSTOM]: 'Custom',
      [blockTypes.WORKSHOPS]: 'Activities',
      [blockTypes.MAPS]: 'Maps',
      [blockTypes.HEADERS]: 'Headers',
      [blockTypes.EXHIBITORS]: 'Exhibitors',
      [blockTypes.FREE]: 'Free block',
      [blockTypes.CONTACT]: 'Contact Form',
    }
  },
  es: {
    placeholder: {
      ageGateDescription: 'Ej: Debes tener 18 o más para entrar.',
      ageGateErrorMsg: 'Ej: No tienes edad suficiente para entrar en este sitio.',
    },
    buttons: {
      close: 'Cerrar',
      back: 'Atrás',
      back_to_composer: 'Volver a Composer',
      restore: 'Restaurar',
      add_content: 'Agregar Contenido',
      select_image: 'Seleccionar Imagen',
      manage_slides: 'Editar Diapositivas',
      add_slide: 'Agregar Diapositiva',
      manage_links: 'Editar enlaces',
      manage_iframe_src: "Editar link del iframe",
      manage_information: 'Editar Informacion',
      manage_colours: 'Editar colores',
      manage_attendee_types: 'Editar tipos de participante',
      add_font: 'Agregar una fuente',
      add_link: 'Agregar enlace',
      add_color: 'Agregar color',
      apply_changes: 'Aplicar Cambios',
      discard_changes: 'Descartar Cambios',
      url_image: 'Imagen desde enlace',
      upload_image: 'Subir imagen',
      edit_code: 'Editar el Código',
      delete_page: 'Eliminar Página',
      duplicate_page: 'Duplicar página',
      copy_block: 'Copiar bloque',
      paste_block: 'Pegar bloque',
      duplicate_block: 'Duplicar bloque',
      copy_content: 'Copiar contenido',
      paste_content: 'Pegar contenido',
      change_friendly_url: 'Cambiar URL amigable',
      move: 'Mover',
      delete: 'Eliminar',
      copy: 'Copiar',
      paste: 'Pegar',
      duplicate: 'Duplicar',
      select_font: 'Seleccionar fuente',
      change_font: 'Cambiar la fuente',
      update_global_font: 'Actualizar fuentes del sitio',
      edit: 'Editar',
      update:"Actualizar",
    },
    errors:{
      not_image_format: 'Formato de imagen inválido',
      not_font_format: "Formato de fuente inválido",
      exceeded_limit: 'Tamaño de archivo excedido',
      empty_name: 'El campo nombre es obligatorio',
      empty_password: 'La contraseña no puede estar vacía',
    },
    info:{
      valid_font_formats: "Sólo se permiten los formatos ttf y woff.",
      first_font_is_global: 'La primera que elijas será usada por defecto en este sitio',
      default_font_is: 'Fuente por defecto: {{font}}',
    },
    labels: {
      attendee_fields: 'Campos de participante',
      b2b_fields: 'Campos de networking',
      b2b_how_to: 'Escoge la información a mostrar para un tipo de participante arrastrando los campos disponibles.',
      networking_profile: 'Perfil de networking',
      attendee_type: 'Tipo de participante',
      types_to_show:'Perfiles de networking a mostrar en el website',
      hide_navbar: 'Ocultar barra de navegación',
      hide_from_navbar: 'Ocultar de la barra de navegación',
      hide_footer: 'Ocultar pie de página',
      hide_from_footer: 'Ocultar del pie de página',
      text: 'Texto',
      open_in_new_tab: 'Abrir en una nueva pestaña',
      description: 'Descripción (opcional)',
      name: 'Nombre (requerido)',
      scoped_css_note: 'Nota: por favor utiliza reglas con el contexto apropiado en tu CSS personalizado; utilizar reglas globales puede afectar negativamente los estilos del resto de tu sitio.',
      js_note: 'Nota: por favor ten cuidado con lo que insertas aquí; errores de sintaxis o de ejecución pueden hacer que tu sitio no funcione apropiadamente.',
      placeholder_b2b_drop: 'Arrastra o escribe los campos que quieras usar adentro de ${}. Por ejemplo ${Micampo}',
      fonts: 'Fuentes',
      site_fonts: 'Fuentes usadas en este sitio',
      fonts_in_account: 'Fuentes en tu cuenta',
      font_preview: 'Previsualización',
      max_width: 'Ancho máximo del sitio',
      custom_domain: 'Dominio personalizado',
      allow_indexing: 'Permitir la indexación en buscadores',
      indexing:'Indexación en buscadores',
      site_password: 'Contraseña del sitio',
      enable_site_password: 'Habilitar contraseña en el sitio',
      backdrop_color: 'Color de fondo',
      popup_color: 'Color del pop up',
      button_color: 'Color del botón',
      error_msg: 'Mensaje de error',
      minimum_age: 'Edad mínima',
      security: 'Seguridad',
      enable_age_gate: 'Habilitar restricción de edad en el sitio',
      password: 'Contraseña',
      login_main_color: 'Color principal del login',
      iframe_note: "Para garantizar un correcto redimensionamiento del iframe, el sitio web objetivo debe tener el script de la librería iframe-resizer.",
    },
    warnings:{
      create_conference_and_participant: 'Por favor crea una conferencia y por lo menos un participante',
    },
    block_types: {
      [blockTypes.REGISTRATION]: 'Registro',
      [blockTypes.REGISTRATION_TYPES]: 'Tipos de Registro',
      [blockTypes.B2B_MATCHMAKING]: 'B2B matchmaking',
      [blockTypes.COOKIE_CONSENT]: 'Consentimiento de cookies',
      [blockTypes.NETWORKING_LOGIN]: 'Login de Ruedas de Negocio',
      [blockTypes.ATTENDEES]: 'Asistentes',
      [blockTypes.SPEAKERS]: 'Ponentes',
      [blockTypes.SPONSORS]: 'Patrocinadores',
      [blockTypes.NAVBAR]: 'Barras de Navegación',
      [blockTypes.FOOTER]: 'Pies de página',
      [blockTypes.CUSTOM]: 'Personalizados',
      [blockTypes.WORKSHOPS]: 'Actividades',
      [blockTypes.MAPS]: 'Mapas',
      [blockTypes.HEADERS]: 'Encabezados',
      [blockTypes.EXHIBITORS]: 'Expositores',
      [blockTypes.FREE]: 'Bloque Libre',
      [blockTypes.CONTACT]: 'Formulario de Contacto',
    }
  },
  fr: {
    placeholder: {
      ageGateDescription: 'Ex : Vous devez avoir 18 ans ou plus pour entrer.',
      ageGateErrorMsg: "Ex : Vous n'êtes pas assez âgé pour entrer sur ce site.",
    },
    buttons: {
      close: 'Fermer',
      back: 'Retour',
      back_to_composer: 'Retour au Composer',
      restore: 'Restituer',
      add_content: 'Ajoteur un contenu',
      select_image: "Sélectionner l'image",
      manage_slides: 'Gérer les slides',
      add_slide: 'Ajouter une diapositive',
      manage_links: 'Gérer les',
      manage_iframe_src: "Gérer le lien iframe",
      manage_information: "Gérer l'information",
      manage_colours: 'Gestion des couleurs',
      manage_attendee_types: 'Gérer les types de participants',
      add_font: 'Ajouter une police',
      add_link: 'Ajouter un lien',
      add_color: 'Ajouter une couleur',
      apply_changes: 'Appliquer les changements',
      discard_changes: 'Rejeter les changements',
      url_image: "Image tirée de l'URL",
      upload_image: "Charger l'image",
      edit_code: 'Éditer le code',
      delete_page: 'Supprimer la page',
      duplicate_page: 'Dupliquer la page',
      copy_block: 'Copier le bloc',
      paste_block: 'Collez le bloc',
      duplicate_block: 'Dupliquer le bloc',
      copy_content: 'Copier le contenu',
      paste_content: 'Collez le contenu',
      change_friendly_url: 'Modifier friendly URL',
      move: 'Déplacer',
      delete: 'Supprimer',
      copy: 'Copier',
      paste: 'Coller',
      duplicate: 'Dupliquer',
      select_font: 'Sélectionnez la police',
      change_font: 'Changer la police',
      update_global_font: 'Changer les polices du site',
      edit: 'Éditer',
      update:"actualiser",
    },
    errors:{
      not_image_format: "Format d'image invalide",
      not_font_format: "Format de police invalide",
      exceeded_limit: 'Dépassement de la limite de taille du fichier',
      empty_name: 'Le champ nom est obligatoire',
      empty_password: 'Le mot de passe ne peut pas être vide',
    },
    info:{
      valid_font_formats: "Seuls les formats ttf et woff sont autorisés.",
      first_font_is_global: 'La première police que vous choisissez sera utilisée par défaut pour ce site',
      default_font_is: 'Police par défaut : {{font}}',
    },
    labels: {
      attendee_fields: 'Informations du participant',
      b2b_fields: 'Informations du networking',
      b2b_how_to: 'Sélectionnez les informations à afficher pour un type de participant en faisant glisser les zones disponibles.',
      networking_profile: 'Profil du networking',
      attendee_type: 'Type de participant',
      types_to_show:'Profils de réseautage à afficher sur le site Web',
      hide_navbar: 'Masquer la barre de navigation',
      hide_from_navbar: 'Masquer dans la barre de navigation',
      hide_footer: 'Cacher le pied de page',
      hide_from_footer: 'Cacher du pied de page',
      text: 'Texte',
      open_in_new_tab: 'Ouvrir dans un nouvel onglet',
      description: 'Description (optionnel)',
      name: 'Nom (obligatoire)',
      scoped_css_note: "Remarque : veuillez utiliser des règles de portée appropriées dans votre CSS personnalisé ; l'utilisation de règles globales pourrait affecter négativement les styles du reste de votre site.",
      js_note: 'Remarque: soyez attentif à ce que vous insérez ici. toute erreur de syntaxe ou d\'exécution pourrait endommager votre site Web.',
      placeholder_b2b_drop: 'Déposez ou écrivez les champs que vous voulez utiliser dans ${}. Par exemple ${Myfield} Par exemple',
      fonts: 'Polices',
      site_fonts: 'Polices utilisées sur ce site',
      fonts_in_account: 'Polices dans votre compte',
      font_preview: 'Prévisualisation',
      max_width: 'Page Largeur max',
      custom_domain: 'Domaine personnalisé',
      allow_indexing: "Permettre l'indexation dans les moteurs de recherche",
      indexing:"Indexation sur les moteurs de recherche",
      site_password: 'Mot de passe du site',
      enable_site_password: 'Activer le mot de passe du site',
      backdrop_color: 'Couleur de fond',
      popup_color: 'Pop up couleur',
      button_color: "Couleur du bouton",
      error_msg: "Message d'erreur",
      minimum_age: "l'âge minimum",
      security: 'Sécurité',
      enable_age_gate: "Autoriser la restriction d'âge sur le site",
      password: 'Mot de passe',
      login_main_color: 'Login couleur principale',
      iframe_note: "Pour garantir un redimensionnement correct de l'iframe, le site cible doit avoir le script de bibliothèque iframe-resizer.",
    },
    warnings:{
      create_conference_and_participant: 'Veuillez créer un intervenant et au moins un participant',
    },
    block_types: {
      [blockTypes.REGISTRATION]: 'Enregistrement',
      [blockTypes.REGISTRATION_TYPES]: 'Types d\'enregistrement',
      [blockTypes.B2B_MATCHMAKING]: 'Mise en relation B2B',
      [blockTypes.COOKIE_CONSENT]: 'Autorisation de cookie',
      [blockTypes.NETWORKING_LOGIN]: 'Connexion au réseau',
      [blockTypes.ATTENDEES]: 'Participants',
      [blockTypes.SPEAKERS]: 'Intervenants',
      [blockTypes.SPONSORS]: 'Sponsors',
      [blockTypes.NAVBAR]: 'Barre de navigation',
      [blockTypes.FOOTER]: 'Pied de page',
      [blockTypes.CUSTOM]: 'Personnalisé',
      [blockTypes.WORKSHOPS]: 'Activités',
      [blockTypes.MAPS]: 'Cartes',
      [blockTypes.HEADERS]: 'En-têtes',
      [blockTypes.EXHIBITORS]: 'Exposants',
      [blockTypes.FREE]: 'Bloc libre',
      [blockTypes.CONTACT]: 'Formulaire de contact',
    }
  },
  de: {
    placeholder: {
      ageGateDescription: 'Ex: You must be 18 or older to enter.',
      ageGateErrorMsg: 'Ex: You are not old enough to enter this site.',
    },
    buttons: {
      close: 'Close',
      back: 'Back',
      back_to_composer: 'Back to Composer',
      restore: 'Restore',
      add_content: 'Add Content',
      select_image: 'Select Image',
      manage_slides: 'Manage Slides',
      add_slide: 'Add Slide',
      manage_links: 'Manage Links',
      manage_iframe_src: "Manage Iframe link",
      manage_information: 'Manage Information',
      manage_colours: 'Manage colors',
      manage_attendee_types: 'Manage attendee types',
      add_link: 'Add Link',
      add_color: 'Add Color',
      apply_changes: 'Apply Changes',
      discard_changes: 'Discard Changes',
      url_image: 'Image from URL',
      upload_image: 'Upload image',
      edit_code: 'Edit Code',
      delete_page: 'Delete Page',
      duplicate_page: 'Duplicate Page',
      copy_block: 'Copy block',
      paste_block: 'Paste block',
      duplicate_block: 'Duplicate block',
      copy_content: 'Copy content',
      paste_content: 'Paste content',
      change_friendly_url: 'Change friendly URL',
      move: 'Move',
      delete: 'Delete',
      copy: 'Copy',
      paste: 'Paste',
      duplicate: 'Duplicate',
    },
    errors:{
      not_image_format: 'Invalid image format',
      exceeded_limit: 'File size limit exceeded',
      empty_password: 'Password cannot be empty',
    },
    labels: {
      attendee_fields: 'Attendee Fields',
      b2b_fields: 'Networking Fields',
      b2b_how_to: 'Choose the information to display for one participant type by dragging the available fields.',
      networking_profile: 'Networking profile',
      attendee_type: 'Tarif',
      types_to_show:'Networking Profiles to be shown on the website',
      hide_navbar: 'Hide Navbar',
      hide_from_navbar: 'Hide from the Navbar',
      hide_footer: 'Hide Footer',
      hide_from_footer: 'Hide from the Footer',
      text: 'Text',
      open_in_new_tab: 'Open link in new tab',
      description: 'Description (optional)',
      name: 'Name (required)',
      scoped_css_note: 'Note: please use appropriately scoped rules in your custom CSS; using global rules might negatively affect the styles of rest of your site.',
      js_note: 'Note: please be mindful of what you insert here; any syntax or runtime errors might break your website.',
      placeholder_b2b_drop: 'Drop or write the fields you want to use inside ${}. For example ${Myfield}',
      fonts: 'Fonts',
      font_preview: 'Font Preview',
      max_width: 'Site Max Width',
      custom_domain: 'Benutzerdefinierten Domain',
      allow_indexing: 'Allow search engine indexing',
      indexing:'Search engine indexing',
      site_password: 'Site password',
      enable_site_password: 'Enable site password',
      backdrop_color: 'Backdrop color',
      popup_color: 'Pop up color',
      button_color: 'Button color',
      error_msg: 'Error message',
      minimum_age: 'Minimum age',
      security: 'Security',
      enable_age_gate: 'Enable age restriction to site',
      password: 'Password',
      login_main_color: 'Hauptfarbe der Anmeldung',
      iframe_note: "To guarantee a correct resizing of the iframe, the target website must have the iframe-resizer library script.",
    },
    warnings:{
      create_conference_and_participant: 'Please create a conference and at least one participant',
    },
    block_types: {
      [blockTypes.REGISTRATION]: 'Registration',
      [blockTypes.REGISTRATION_TYPES]: 'Registration types',
      [blockTypes.B2B_MATCHMAKING]: 'B2B matchmaking',
      [blockTypes.COOKIE_CONSENT]: 'Cookie consent',
      [blockTypes.NETWORKING_LOGIN]: 'Networking login',
      [blockTypes.ATTENDEES]: 'Attendees',
      [blockTypes.SPEAKERS]: 'Speakers',
      [blockTypes.SPONSORS]: 'Sponsors',
      [blockTypes.NAVBAR]: 'Navbar',
      [blockTypes.FOOTER]: 'Footer',
      [blockTypes.CUSTOM]: 'Custom',
      [blockTypes.WORKSHOPS]: 'Activities',
      [blockTypes.MAPS]: 'Maps',
      [blockTypes.HEADERS]: 'Headers',
      [blockTypes.EXHIBITORS]: 'Exhibitors',
      [blockTypes.FREE]: 'Free block',
      [blockTypes.CONTACT]: 'Contact Form',
    }
  }
}

import { saveComposerSetting } from './app';


export const updateAllowSearchEngineIndexing = () => {
  return (dispatch, getState) => {
    dispatch(saveComposerSetting('allow_search_engine_indexing'));
  }
};

export const UPDATE_ALLOW_INDEXING = 'UPDATE_ALLOW_INDEXING';
export const changeAllowSearchEngineIndexingState = (value) => {
  return {
    type: UPDATE_ALLOW_INDEXING,
    value
  }
}

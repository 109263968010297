import { CALL_API } from "composer-blocks/lib/middleware/api";
import { getToken, getTokenContent } from "../helpers/auth";
import { objectToQueryString, setCurrentPage } from "composer-blocks/lib/actions/site";
import { insertPage } from './pages';
import { getCurrentPageId } from 'composer-blocks/lib/helpers/pages';
import { getComposerCategory } from '../helpers/api';

let publishedSiteCallback;

// Request for the composer settings for the editor
export const COMPOSER_SETTINGS_SUCCESS = 'COMPOSER_SETTINGS_SUCCESS';
export const COMPOSER_SETTINGS_REQUEST = 'COMPOSER_SETTINGS_REQUEST';
export const COMPOSER_SETTINGS_FAILURE = 'COMPOSER_SETTINGS_FAILURE';
export const fetchComposerSite = (event_uri, params_json) => {
  return {
    [CALL_API]: {
      types: [ COMPOSER_SETTINGS_REQUEST, COMPOSER_SETTINGS_SUCCESS, COMPOSER_SETTINGS_FAILURE ],
      endpoint: `events/${event_uri}/composer_site${objectToQueryString(params_json)}`,
      options: {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        },
      }
    }
  }
};


export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const setActivePage = (pageId) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_PAGE,
      activePageId: pageId
    });
  };
};

export const SET_ACTIVE_CONTENT = 'SET_ACTIVE_CONTENT';
export const setActiveContent = (contentId) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_CONTENT,
      activeContentId: contentId
    });
  };
};

export const SET_MODIFIED_CONTENT = 'SET_MODIFIED_CONTENT';
export const setModifiedContent = (contentId, value) => {
  return (dispatch, getState) => {
    const originalContent = getState().contents[contentId];

    dispatch({
      type: SET_MODIFIED_CONTENT,
      originalContent,
      value
    });
  };
};

export const SET_MODIFIED_PROPERTY = 'SET_MODIFIED_PROPERTY';
export const setModifiedProperty = (propertyId, value) => {
  return (dispatch, getState) => {
    const originalProperty = getState().properties[propertyId];

    dispatch({
      type: SET_MODIFIED_PROPERTY,
      originalProperty,
      value
    });
  };
};

export const RESTORE_CONTENT = 'RESTORE_CONTENT';
export const restoreContent = () => {
  return (dispatch, getState) => {
    dispatch({
      type: RESTORE_CONTENT,
      contentId: getState().app.activeContentId
    })
  }
};

export const SAVE_MODIFIED_ITEMS = 'SAVE_MODIFIED_ITEMS';
export const saveModifiedItems = () => {
  return (dispatch, getState) => {
    const modifiedContents = getState().app.modifiedItems.contents;
    const modifiedProperties = getState().app.modifiedItems.properties;

    dispatch({
      type: SAVE_MODIFIED_ITEMS,
      modifiedContents,
      modifiedProperties
    });

    dispatch(saveComposerSetting('draft_site'));
  };
};

export const SAVE_COMPOSER_SETTING_REQUEST = 'SAVE_COMPOSER_SETTING_REQUEST';
export const SAVE_COMPOSER_SETTING_SUCCESS = 'SAVE_COMPOSER_SETTING_SUCCESS';
export const SAVE_COMPOSER_SETTING_FAILURE = 'SAVE_COMPOSER_SETTING_FAILURE';
export const saveComposerSetting = (attrToSave) => {
  return (dispatch, getState) => {
    const { event_uri } = getTokenContent();
    const { pageOrder, pages, blocks, navbars, contents, properties, footers, globalSettings, otherData } = getState();
    let attr = {}
    switch (attrToSave) {
      case 'draft_site':
        attr['draft_site'] = { pageOrder, pages, blocks, navbars, contents, properties, footers, globalSettings }
        break;
      case 'allow_search_engine_indexing':
        attr['allow_search_engine_indexing'] = otherData.allowSearchEngineIndexing;
        break
      case 'custom_domain':
        attr['custom_domain'] = otherData.customDomain;
        break
      default:
        console.error('Attribute not specified');
    }
    const body = {
      composer_settings: {
        data: {
          attributes: attr
        }
      },
      website_lang: sessionStorage.getItem("website_lang")
    };

    dispatch({
      [CALL_API]: {
        types: [ SAVE_COMPOSER_SETTING_REQUEST, SAVE_COMPOSER_SETTING_SUCCESS, SAVE_COMPOSER_SETTING_FAILURE ],
        endpoint: `events/${event_uri}/composer_settings`,
        options: {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
          method: 'PATCH',
          body: JSON.stringify(body)
        }
      }
    });
  };
};

export const PUBLISH_MODIFIED_ITEMS = 'PUBLISH_MODIFIED_ITEMS';
export const publishModifiedItems = () => {
  return (dispatch, getState) => {
    const modifiedContents = getState().app.modifiedItems.contents;
    const modifiedProperties = getState().app.modifiedItems.properties;

    dispatch({
      type: PUBLISH_MODIFIED_ITEMS,
      modifiedContents,
      modifiedProperties
    });

    dispatch(publishSite());
  };
};

export const PUBLISH_SITE_REQUEST = 'PUBLISH_SITE_REQUEST';
export const PUBLISH_SITE_SUCCESS = 'PUBLISH_SITE_SUCCESS';
export const PUBLISH_SITE_FAILURE = 'PUBLISH_SITE_FAILURE';
export const publishSite = () => {
  return (dispatch, getState) => {
    const { event_uri } = getTokenContent();
    const { app, pageOrder, pages, blocks, navbars, contents, properties, footers, globalSettings } = getState();
    const { saveChanges } = app;
    const body = {
      composer_settings: {
        data: {
          attributes: {
            site: { pageOrder, pages, blocks, navbars, contents, properties, footers, globalSettings},
          }
        }
      },
      save_and_publish: saveChanges.active,
      website_lang: sessionStorage.getItem("website_lang")
    };

    dispatch({
      [CALL_API]: {
        types: [ PUBLISH_SITE_REQUEST, PUBLISH_SITE_SUCCESS, PUBLISH_SITE_FAILURE ],
        endpoint: `events/${event_uri}/composer_settings`,
        options: {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          },
          method: 'PATCH',
          body: JSON.stringify(body)
        }
      }
    })
    .then(res => {
      if(res.type === PUBLISH_SITE_SUCCESS){
        if(!!publishedSiteCallback) clearTimeout(publishedSiteCallback);
        publishedSiteCallback = setTimeout(() => dispatch(setSnackbarProps('Changes successfully published!')), 120000);
      }
    });
  };
};

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const uploadFile = (name, description, file) => {
  return (dispatch, getState) => {
    const { event_uri } = getTokenContent();
    const state = getState();

    const data = new FormData();
    data.append('event_file[name]', name);
    data.append('event_file[description]', description);
    data.append('event_file[event_file]', file, file.name);

    return dispatch({
      [CALL_API]: {
        types: [ UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE ],
        endpoint: `events/${event_uri}/event_file_categories/${getComposerCategory(state)}/event_files`,
        options: {
          headers: {
            'Authorization': getToken(),
          },
          method: 'POST',
          body: data
        }
      }
    });
  };
};


export const UPLOAD_FONT_REQUEST = 'UPLOAD_FONT_REQUEST';
export const UPLOAD_FONT_SUCCESS = 'UPLOAD_FONT_SUCCESS';
export const UPLOAD_FONT_FAILURE = 'UPLOAD_FONT_FAILURE';
export const uploadFont = (name, weight, style, fallback, font) => {
  return (dispatch, getState) => {
    const { event_uri } = getTokenContent();
    const data = new FormData();
    data.append('name', name);
    data.append('weight', weight);
    data.append('style', style);
    data.append('fallback', fallback);
    data.append('source', font);
    return dispatch({
      [CALL_API]: {
        types: [ UPLOAD_FONT_REQUEST, UPLOAD_FONT_SUCCESS, UPLOAD_FONT_FAILURE ],
        endpoint: `events/${event_uri}/account_fonts`,
        options: {
          headers: {
            'Authorization': getToken(),
          },
          method: 'POST',
          body: data
        }
      }
    });
  };
};



export const UPDATE_FONT_REQUEST = 'UPDATE_FONT_REQUEST';
export const UPDATE_FONT_SUCCESS = 'UPDATE_FONT_SUCCESS';
export const UPDATE_FONT_FAILURE = 'UPDATE_FONT_FAILURE';
export const updateFont = (id, name, weight, style, fallback) => {
  return (dispatch, getState) => {
    const { event_uri } = getTokenContent();
    const data = new FormData();
    data.append('name', name);
    data.append('weight', weight);
    data.append('style', style);
    data.append('fallback', fallback);
    return dispatch({
      [CALL_API]: {
        types: [ UPDATE_FONT_REQUEST, UPDATE_FONT_SUCCESS, UPDATE_FONT_FAILURE ],
        endpoint: `events/${event_uri}/account_fonts/${id}`,
        options: {
          headers: {
            'Authorization': getToken(),
          },
          method: 'PATCH',
          body: data
        }
      }
    });
  };
};


export const TOGGLE_CONTENT_LIST = 'TOGGLE_CONTENT_LIST';
export const toggleContentList = () => ({
  type: TOGGLE_CONTENT_LIST
});

export const OPEN_MODAL = 'OPEN_MODAL';
export const openModal = (title, message, accept, cancel) => ({
  type: OPEN_MODAL,
  modalProps: {
    title,
    message,
    actions: {
      accept,
      cancel
    }
  }
});

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const closeModal = () => ({
  type: CLOSE_MODAL
});


export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const login = (token) => ({
  [CALL_API]: {
    types: [ LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE ],
    endpoint: 'sso-auth',
    options: {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ token })
    }
  }
});


export const SET_SNACKBAR_PROPS = 'SET_SNACKBAR_PROPS';
export const setSnackbarProps = (message, error = false) => ({
  type: SET_SNACKBAR_PROPS,
  message,
  error
});

export const fetchComposerSettingsIfNeeded = (page) => (dispatch, getState) => {
  const state = getState();
  const events = state.site.entities.events;
  if (!!events) return;
  const { event_uri } = getTokenContent();
  const websiteLang = sessionStorage.getItem("website_lang");
  dispatch(fetchComposerSite(event_uri, { 'website_lang': websiteLang })).then((action) => {
    const composerSettings = action.response.entities.composer_settings;
    const site = composerSettings[Object.keys(composerSettings)[0]].site;
    const existingPages = Object.keys(state.pages).length;
    if ((!site || !site.pages) && existingPages === 0) {
      return dispatch(insertPage())
    } else if (!!site) {
      const pageId = getCurrentPageId(page, site.pages, site.pageOrder);
      return dispatch(setCurrentPage(pageId));
    }
  })
}
